import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { first, from, Observable, switchMap, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';
import { State } from 'src/app/state/app.state';
import { size, TranslocoService } from '@jsverse/transloco';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ModusFileDropzoneCustomEvent } from '@trimble-oss/modus-web-components/loader';
import { handleApolloTemplateError, handleApolloTemplateSuccess } from 'src/app/@core/utils';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { TIdService } from 'src/app/@auth/services/tId.service';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/@core/services/content.service';
import * as utils from 'src/app/@core/utils';
import { documentManagerActions, documentManagerSelectors } from 'src/app/@core/components/document-manager-shell';
import { DocumentTypeFile } from 'src/app/modules/protocol-nerp/models';
import { DocumentsInput } from 'src/app/@core/generated/operations-core-graphql';
import { TemplateResponse } from 'src/app/models/notifications/TemplateResponse';

@Component({
    selector: 'app-upload-file-shell',
    templateUrl: './upload-file-shell.component.html',
    styleUrls: ['./upload-file-shell.component.scss']
})
export class UploadFileShellComponent implements OnInit, OnDestroy {
    organizationId: string;
    protocolId: string;
    projectId: string;
    private onDestroy$ = new EventEmitter<void>();
    isWorking = this.store.selectSignal(documentManagerSelectors.selectDocumentUploadIsWaiting);
    uploadFilesGroup = new FormGroup({
        files: new FormArray<FormGroup<FileFormArrayItem>>([]),
    });
    documentTypesList = this.store.selectSignal(documentManagerSelectors.selectOrganizationDocumentFilesCategories);
    showMessage: boolean = false;
    @ViewChild('fileUpload') fileUpload: any;
    private filesRemovingFromDropZone = 0;
    url: string = `/api/v1/upload/document`;
    formData!: { key: string, value: string }[];
    alertMessage: string = '';
    validationErrors$?: Observable<{ name: string; parameters: any }[] | null>;



    constructor(private httpClient: HttpClient,
        private langSvc: TranslocoService,
        private dialogRef: DialogRef<uploadFileDialogResult>,
        private tidService: TIdService,
        @Inject(DIALOG_DATA) private data: any,
        private store: Store<State>,
        private toast: ToastrService,
        private contentSrv: ContentService) {

        dialogRef.disableClose = true;
        this.organizationId = data.organizationId;
        this.protocolId = data.protocolId;
        this.projectId = data.projectId;

        this.uploadFilesGroup.controls.files.setValue([]);
        this.formData = [
            {
                key: 'fileUploadData', value: JSON.stringify({
                    organizationId: this.organizationId,
                    protocolId: this.protocolId,
                    projectId: this.projectId
                })
            }
        ];
    }

    ngOnInit(): void {
        this.store.select(documentManagerSelectors.selectProtocolFileUploadDialogOpen).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(open => {
            if (!open) {
                this.dialogRef.close();
            }
        });


        this.store.dispatch(documentManagerActions.getProtocolDefinitionFiles({
            variable: {
                where: {
                    expr: {
                        column: 'Id',
                        operator: '=',
                        value: this.protocolId
                    }
                }
            }
        }));

        this.validationErrors$ = this.store.select(documentManagerSelectors.selectDocumentUploadValidationErrors);
        this.store.select(documentManagerSelectors.selectDocumentUploadValidationErrors).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((errors) => {
            if (errors) {
                for (const err of errors) {
                    const response: TemplateResponse = {
                        templateId: err.name,
                        parameters: err.parameters
                    }
                    const message = this.contentSrv.translateTemplate(response);
                    const fileControl = this.uploadFilesGroup.controls.files.controls
                        .find(e => e.value.name == err.parameters.name)
                    if (fileControl) {
                        const errors = fileControl.errors ?? {};
                        errors[err.name] = message;
                        fileControl.setErrors(errors);
                    } else {
                        this.uploadFilesGroup.setErrors({ [err.name]: err.parameters })
                        const errors = this.uploadFilesGroup.errors ?? {};
                        errors[err.name] = message;
                        this.uploadFilesGroup.setErrors(errors);
                    }
                }
            }
        });
    }

    validateProgress(control: AbstractControl): ValidationErrors | null {
        if (control.value < 100) {
            return { 'validateProgress': true };
        }
        return null;
    }

    addFile(newfile: any) {
        let fileForm = new FormGroup<FileFormArrayItem>({
            iconType: new FormControl<string | null>(newfile.iconType),
            name: new FormControl<string | null>(newfile.name, Validators.required),
            nameFile: new FormControl<string | null>(newfile.nameFile, Validators.required),
            size: new FormControl<number | null>(0),
            loaded: new FormControl<number | null>(0),
            category: new FormControl<DocumentTypeFile | undefined>({ key: '', name: '', required: false, maxFileCount: 0, allowedFileExtensions: [] }, this.validateCategory),
            statusbar: new FormControl<number | null>(newfile.statusbar, [Validators.required, this.validateProgress]),
            blobIdentifier: new FormControl<string | null>(null),
            blobInfo: new FormControl<string | null>(null),
            fileType: new FormControl<string | null>(null)
        });
        this.uploadFilesGroup.controls.files.push(fileForm);
    }

    validateCategory(control: AbstractControl): ValidationErrors | null {
        if (control.value.key == '' || !utils.validateGuid(control.value.key)) {
            return { 'validateCategory': true };
        }
        return null;
    }

    removeFile(fileName: string) {
        this.filesRemovingFromDropZone ++;
        const filesForm = this.uploadFilesGroup.controls.files;
        this.uploadFilesGroup.controls.files.removeAt(filesForm.controls.findIndex((e) =>
            e.controls.nameFile.value == fileName));
        this.fileUpload.removeFile(fileName);
    }

    dialogClose() {
        this.store.dispatch(documentManagerActions.protocolFileUploadDialogOpen({ value: false }));
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
        this.onDestroy$.complete();
    }

    onFileSelected($event: ModusFileDropzoneCustomEvent<[File[], string | null]>) {
        const files: File[] = $event.detail[0];

        if (this.filesRemovingFromDropZone > 0) {
            this.filesRemovingFromDropZone--;
            return;
        }

        const formData = new FormData();

        const allFilesEventExistOnForm = files.every((f) => {
            return !!this.uploadFilesGroup.controls.files.value.find((e) => e.nameFile == f.name);
        });
        if (allFilesEventExistOnForm) {
            return;
        }

        this.formData.forEach((e) => {
            formData.append(e.key, new Blob([e.value], { type: 'application/json' }));
        });

        files.forEach((f) => {
            formData.append('file', f, f.name);
            let fileuploading = {
                iconType: this.getFileType(f.type),
                name: f.name,
                nameFile: f.name,
                size: f.size ?? 0,
                category: '',
                statusbar: 0,
            };
            let fileExist = !!this.uploadFilesGroup.controls.files.controls.find((e) => e.get('nameFile')?.value == f.name);
            if (fileExist) {
                return
            }

            this.addFile(fileuploading);
        });

        this.tidService.tokenStream().pipe(
            first(),
            switchMap(t => {
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${t?.tokens?.token || ''}`
                });
                return this.httpClient.post(this.url, formData, {
                    reportProgress: true,
                    observe: 'events',
                    headers: headers
                });
            })
        ).subscribe(
            {
                next: response => {
                    if (response && response.type === HttpEventType.UploadProgress) {
                        const total = response.total || 1;
                        let totalLoaded = Math.round(100 * (response.loaded / total));

                        files.forEach((f) => {
                            let files = this.uploadFilesGroup.controls.files.controls
                                .find((e) => e.get('nameFile')?.value == f.name);
                            if (files) {
                                files.get('statusbar')?.patchValue(totalLoaded);
                                files.get('loaded')?.patchValue(totalLoaded);

                            }
                        });
                    }
                    if (response && response.type === HttpEventType.Response) {
                        const fileResult = response.body as FileUploadResult;
                        fileResult.files?.forEach((f) => {
                            const c = this.uploadFilesGroup.controls.files.controls
                                .find(e => e.value.nameFile == f.name);
                            c?.patchValue({
                                blobIdentifier: f.identifier,
                                blobInfo: f.blobInfo,
                                size: f.size,
                                statusbar: 100
                            });
                            this.filesRemovingFromDropZone ++;
                            this.fileUpload.removeFile(f.name);
                        });
                    }
                },
                error: error => {
                    handleApolloTemplateError(error, this.toast, this.contentSrv);
                    files.forEach(f => {
                        const filesForm = this.uploadFilesGroup.controls.files;
                        this.uploadFilesGroup.controls.files.removeAt(filesForm.controls.findIndex((e) =>
                            e.controls.nameFile.value == f.name));
                        this.fileUpload.removeFile(f.name);
                    });
                },
                complete: () => {
                    handleApolloTemplateSuccess({
                        templateId: 'SUCCESS_FILE_UPLOAD',
                        parameters: {}
                    }, this.toast, this.contentSrv);
                }
            }
        );
    }

    SubmitFiles() {
        const files = this.uploadFilesGroup.controls.files.value;

        const documentInputs: DocumentsInput[] = files.map((f) => {
            return {
                blobIdentifier: f.blobIdentifier ?? '',
                blobInfo: f.blobInfo ?? '',
                name: f.name ?? '',
                size: f.size,
                type: f.category?.key ?? ''
            }
        });
        this.store.dispatch(documentManagerActions.protocolSubmitFilesUploadedLoad({
            input: {
                organizationId: this.organizationId,
                protocolId: this.protocolId,
                projectId: this.projectId,
                documents: documentInputs
            }
        }));
        this.store.dispatch(documentManagerActions.protocolFileUploadDialogOpen({ value: false }));
        
    }

    validateInput(input: string, file: FormGroup) {
        let category = file.controls[input];
        var maxFileCount = category?.value?.maxFileCount;
        // remove error from category
        category.setErrors(null);

        let count = this.uploadFilesGroup.controls.files.value.filter((e) => e.category?.key == category.value.key).length;
        if (count > maxFileCount) {
            this.alertMessage = this.langSvc.translate('The maximum number of files for ' + category.value.name + ' allowed is ') + ' ' + maxFileCount;
            this.showMessage = true;
            category?.setErrors({ 'maxFile': true });
        } else {
            category?.clearValidators();

        }


    }


    getFileType(fileType: string) {

        switch (fileType) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'file-xls';
            case 'application/pdf':
                return 'file-pdf';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'file-doc';
            case 'text/csv':
                return 'file-csv';
            case 'application/rtf':
                return 'file-rtf';
            case 'text/plain':
                return 'file-txt';
            case 'application/x-zip-compressed':
                return 'file-zip';
            case 'image/jpeg':
            case 'image/png':
                return 'file-image';
            default:
                return 'file-unknown';
        }

    }





    onCloseMessage(e: Event): void {
        this.showMessage = false;
    }

    protected readonly Object = Object;
}

export interface uploadFileDialogResult {
    idDocument: string;
    documentName: string;
}

export interface FileUploadResult {
    organizationId: string;
    protocolId: string;
    projectId: string;
    files?: FileUploadFileResult[];
    count: number;
    size: number;
}

export interface FileUploadFileResult {
    name: string;
    identifier: string;
    blobInfo: string;
    size: number;
    oldIdentifier: string;
}

export interface FileFormArrayItem {
    iconType: FormControl<string | null>,
    name: FormControl<string | null>,
    nameFile: FormControl<string | null>,
    loaded: FormControl<number | null>,
    size: FormControl<number | null>,
    category: FormControl<DocumentTypeFile | null | undefined>
    statusbar: FormControl<number | null>,
    blobIdentifier: FormControl<string | null>,
    blobInfo: FormControl<string | null>,
    fileType: FormControl<string | null>,
}
