<div class="">
    <app-spinner [isWorking]="isWorking()"></app-spinner>
    <div class="">
        <ccx-grid-filter-panel [counter]="filterCounter" (onGridSearchBy)="gridSearchBy($event)"
                               (onToggleFilter)="filterToggled()"></ccx-grid-filter-panel>
        <div class="mt-1">
            <modus-message type="info" [icon]="'lock'" *ngIf="isSelectedProjectLocked">
                {{ 'All documents are locked as the project has been marked verified.' | transloco }}
            </modus-message>
        </div>
        <ccx-grid [overlayLoadingTemplate]="'<span></span>'" [overlayNoRowsTemplate]="'<span></span>'"
            [dataSource]="OrganizationDocumentDataSource" [rowSelection]="'multiple'" [gridOptions]="gridOptions"
            (selectionChanged)="onSelectionChanged($event)" (modelUpdated)="onModelUpdated($event)"
            [suppressMovableColumns]="true" [suppressCellFocus]="true" (onCellClick)="onCellLinkClick($event)"
            >
        </ccx-grid>
        <ng-container *ngIf="(OrganizationDocumentDataSource?.dataResponse$ | async) as dataModel">
            <div *ngIf="dataModel.data.length == 0" class="no-rows-overlay__wrapper">
                <div class="no-rows-overlay__content">
                    <svg-icon [svgClass]="'no-rows-overlay-svg-icon'" name="file"></svg-icon>
                    <span class="no-rows-overlay__text">{{'No document found' | transloco}}</span>
                    <ccx-button
                    [disabled]="isSelectedProjectLocked"
                    width="7.5em"
                    height="2.5625rem"
                    size="medium"
                    buttonStyle="outline"
                    color="secondary"
                    (buttonClick)="onClickUpload()">

                        <svg-icon class="main-view-add-icon-svg"  name="upload-arrow">
                        </svg-icon>{{'Upload'| transloco}}
                    </ccx-button>
                </div>
            </div>
        </ng-container>
</div>
